import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        // 默认
        path: '/',
        name: 'Index',
        component: () => import('@/views/index.vue')
    },
    {
        // 默认
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/about.vue')
    },
    {
        path: '/derivative',
        name: 'Derivative',
        component: () => import('@/views/derivative.vue')
    },
    {
        path: '/doc',
        name: 'Doc',
        component: () => import('@/views/doc.vue')
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import('@/views/info.vue')
    },
    {
        path: '/news',
        name: 'News',
        component: () => import('@/views/news.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/contact.vue')
    },
    {
        path: '/production',
        name: 'Production',
        component: () => import('@/views/production.vue')
    }
];

const router = new VueRouter({
    mode: 'hash', // 使用 hash 模式
    routes
});

export default router;
