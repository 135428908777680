import Vue from 'vue';
import App from './App.vue';
import router from './router';
import waterfall from 'vue-waterfall2'
Vue.config.productionTip = false;


Vue.use(waterfall);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

