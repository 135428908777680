<template>
    <div class="footer">
        Copyright © 2023 The Ding Wei Archives. All Rights Reserved.
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #000;
    font-size: 14px;
}
</style>
