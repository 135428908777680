<template>
    <div ref="headerSection" :class="showHeaderSticky ? 'header-section fixed-top w-100 header-sticky' : 'header-section fixed-top w-100'">
        <button v-if="!showMenuState" type="button" class="navbar-toggler collapsed" @click="showMenu">
            <span class="lni lni-menu"></span>
        </button>
        <router-link class="logoShow" to="index">
            <img style="width: 120px;margin-bottom: 20px;" src="@/assets/img/logo.png" alt="/">
        </router-link>
        <div class="menu">
            <div class="area">
                <div v-if="language === 'zh'" @click="toMenu(menu)" :class="currentMenu === menu.id ? 'item active' : 'item'" v-for="menu in menuList">
                    {{ menu.name }}
                </div>
                <div v-if="language === 'en'" @click="toMenu(menu)" :class="currentMenu === menu.id ? 'item active' : 'item'" v-for="menu in menuList">
                    {{ menu.nameEn }}
                </div>
                <div v-if="language === 'zh'" class="item" @click="changeLanguage">English</div>
                <div v-if="language === 'en'" class="item" @click="changeLanguage">中文</div>
            </div>
        </div>
        <div class="mobile-menu" v-if="showMenuState">
            <button type="button" style="color: white" class="navbar-toggler collapsed" @click="showMenu">
                <span class="lni lni-close"></span>
            </button>
            <div class="area">
                <div v-if="language === 'zh'" @click="toMenu(menu)" :class="currentMenu === menu.id ? 'item active' : 'item'" v-for="menu in menuList">
                    {{ menu.name }}
                </div>
                <div v-if="language === 'en'" @click="toMenu(menu)" :class="currentMenu === menu.id ? 'item active' : 'item'" v-for="menu in menuList">
                    {{ menu.nameEn }}
                </div>
                <div v-if="language === 'zh'" class="item" @click="changeLanguage">English</div>
                <div v-if="language === 'en'" class="item" @click="changeLanguage">中文</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            // 语言
            language: localStorage.getItem('language') || 'zh',
            currentMenu: localStorage.getItem('currentMenu') || "1",
            showMenuState: false,
            showHeaderSticky: false,
            lastScrollPosition: 0,
            menuList: [
                {
                    id: "1",
                    name: '首页',
                    nameEn: 'Home',
                    path: '/index'
                },
                {
                    id: "2",
                    name: '最新消息',
                    nameEn: 'News',
                    path: '/news'
                },
                {
                    id: "3",
                    name: '关于',
                    nameEn: 'About',
                    path: '/about'
                },
                {
                    id: "4",
                    name: '作品',
                    nameEn: 'Works',
                    path: '/production'
                },
                {
                    id: "5",
                    name: '文章',
                    nameEn: 'Articles',
                    path: '/doc'
                },
                {
                    id: "6",
                    name: '衍生品',
                    nameEn: 'Art Derivatives',
                    path: '/derivative'
                },
                {
                    id: "7",
                    name: '联系方式',
                    nameEn: 'Contact',
                    path: '/contact'
                },
            ]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        this.$nextTick(() => {
            window.addEventListener('scroll', this.handleScroll);
        });
    },
    methods: {
        changeLanguage() {
            if (this.language === 'zh') {
                this.language = 'en';
            } else {
                this.language = 'zh';
            }
            localStorage.setItem('language', this.language);
            console.log(this.currentMenu);
            window.location.reload();
        },
        toMenu(menu) {
            this.currentMenu = menu.id;
            // localStorage.setItem('currentMenu', this.currentMenu);
            this.showMenuState = false;
            // 获取当前路由
            const currentPath = this.$route.path;
            // 如果当前路由和点击的路由相同，刷新页面
            if (currentPath === menu.path) {
                window.location.reload();
                return;
            }
            this.$router.push(menu.path);
        },
        showMenu() {
            this.showMenuState = !this.showMenuState;
        },
        handleScroll() {
            if (!this.$refs.headerSection) return;

            const rect = this.$refs.headerSection.getBoundingClientRect();
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            if (currentScrollPosition === 0) {
                // 滚动到顶部，重置 showHeaderSticky 的值
                this.showHeaderSticky = false;
            } else {
                this.showHeaderSticky = rect.top <= 1; // Adjust the value as needed
            }

            this.lastScrollPosition = currentScrollPosition;
        }

    }
}
</script>

<style scoped>
@media (max-width: 1000px) {
    .menu {
        display: none;
    }
    .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.3s;
    }

    .mobile-menu .area {
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 10rem;
        flex-direction: column;

    }

    .mobile-menu .item {
        margin: 5px 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 22px;
        font-weight: 1000;
        cursor: pointer;
        transition: all 0.3s;
    }
    .mobile-menu .item:hover {
        color: #e81b2d;
    }
    .mobile-menu .item.active {
        color: #e81b2d;
    }
}
@media (min-width: 1000px) {
    .collapsed {
        display: none;
    }
    .mobile-menu {
        display: none;
    }
    .menu {
        display: flex;
        width: 100%;
    }

    .menu .area {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .menu .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        height: 40px;
        font-size: 22px;
        font-weight: 1000;
        cursor: pointer;
        transition: all 0.3s;
    }
    .menu .item:hover {
        color: #e81b2d;
    }
    .menu .item.active {
        color: #e81b2d;
    }
}
</style>
