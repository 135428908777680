<template>
    <div id="app">
        <Header />
        <router-view />
        <Footer />
    </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
    name: 'App',
    components: {
        Footer,
        Header
    }
}
</script>

<style>
@import '@/assets/css/bootstrap.css';
@import '@/assets/css/LineIcons.css';
@import '@/assets/css/main.css';
@import '@/assets/css/quill.css';
@import '@/assets/css/swiper.min.css';
@import '@/assets/css/portalCms.css';

#app {
    font-family: "Helvetica", serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.618;
    color: #0c0032;
    overflow-x: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
